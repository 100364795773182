import {FC, useEffect, useState} from 'react'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'

import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import idNgoMessages from './messages/id-ngo.json'
import idUmumMessages from './messages/id-umum.json'
import {WithChildren} from '../helpers'
import {useAuth} from '../../app/modules/auth'

const allMessages = {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  'id-ngo': idNgoMessages,
  'id-umum': idUmumMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  // const locale = useLang()
  // const messages = allMessages[locale]
  const [messages, setMessages] = useState<any>()

  const {currentUser} = useAuth()

  useEffect(() => {
    console.log(currentUser)
    if (currentUser?.data?.company?.company_type === 'ngo') {
      setMessages(allMessages['id-ngo'])
    } else if (currentUser?.data?.company?.company_type === 'laz') {
      setMessages(allMessages['id-ngo'])
    } else {
      setMessages(allMessages['id-umum'])
    }
  }, [currentUser])

  return (
    <IntlProvider locale={'id'} messages={messages}>
      <>{children}</>
    </IntlProvider>
  )
}

export {I18nProvider}
