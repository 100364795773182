import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {ProgramSingleResultInterface} from '../interfaces/program-single-result.interface'
import {ProgramData, ProgramResultInterface} from '../interfaces/program-result.interface'
import {
  GetAllProgramParam,
  GetAllProgram,
  DeleteProgram,
  SubmitProgram,
  UpdateProgram,
  BulkSortingProgram,
  GetSidikProducts,
} from '../providers/program.provider'
import moment from 'moment'
import {createJSONStorage, persist} from 'zustand/middleware'
import {SidikProductData} from '../interfaces/sidik-product-result.interface'

interface ProgramState {
  programLoading: boolean
  programItems: ProgramData[]
  programSelectDatas: SelectData[]
  programModel?: ProgramResultInterface
  getPrograms: (params: GetAllProgramParam) => void
  onDelete: (id: string, params: GetAllProgramParam) => void
  setHotProgramOrdering: (items: ProgramData[]) => void
  getSidikPrograms: () => void
  sidikProgramItems: SidikProductData[]
  sidikProgramSelectDatas: SelectData[]
}

export const useProgramStore = create(
  persist<ProgramState>(
    (set, get) => ({
      programLoading: false,
      programItems: [],
      programSelectDatas: [],
      programModel: undefined,
      sidikProgramItems: [],
      sidikProgramSelectDatas: [],
      getPrograms: async (params: GetAllProgramParam) => {
        set({programLoading: true, programItems: [], programSelectDatas: []})
        const response = await GetAllProgram(params)
        set({programModel: response})
        if (response.status) {
          if (response.data.items.length > 0) {
            let transformDatas: SelectData[] = []
            for (const item of response.data.items) {
              transformDatas.push({
                label: item.title,
                value: item.id,
              })
            }
            set({
              programItems: response.data.items,
              programSelectDatas: transformDatas,
            })
          } else {
            set({
              programItems: [],
              programSelectDatas: [],
            })
          }
        } else {
          set({
            programItems: [],
            programSelectDatas: [],
          })
        }
        set({programLoading: false})
      },
      onDelete: async (id: string, params: GetAllProgramParam) => {
        const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
        if (confirm === true) {
          set({programLoading: true})
          const response = await DeleteProgram(id)
          if (response.status) {
            toast.success(response.message)
          } else {
            toast.error(response.message)
          }
          get().getPrograms(params)
          set({programLoading: false})
        }
      },
      setHotProgramOrdering: async (items: ProgramData[]) => {
        const ids = items.map((el) => el.id)
        const sorts = items.map((el, index) => index + 1)

        console.log(ids, sorts)

        set({programItems: items})

        const resp = await BulkSortingProgram(ids, sorts)
        toast.success(resp.message)
      },
      getSidikPrograms: async () => {
        set({sidikProgramItems: [], sidikProgramSelectDatas: []})
        const response = await GetSidikProducts()
        if (response.status) {
          if (response.data.length > 0) {
            let transformDatas: SelectData[] = []
            for (const item of response.data) {
              transformDatas.push({
                label: item.product_name,
                value: item.id,
              })
            }
            set({
              sidikProgramItems: response.data,
              sidikProgramSelectDatas: transformDatas,
            })
          } else {
            set({
              sidikProgramItems: [],
              sidikProgramSelectDatas: [],
            })
          }
        } else {
          set({
            sidikProgramItems: [],
            sidikProgramSelectDatas: [],
          })
        }
      },
    }),
    {name: 'program-storage', storage: createJSONStorage(() => localStorage)}
  )
)

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ProgramFormState {
  field?: ProgramData
  setField?: (item: ProgramData) => void
  forms?: FormInput[]
  addForms?: (form: FormInput) => void
  hiddenForm?: (index: number, value: boolean) => void
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useProgramFormStore = create(
  persist<ProgramFormState>(
    (set, get) => ({
      field: undefined,
      setField: (item: ProgramData) => {
        set({field: item})
      },
      formParam: undefined,
      setForms: (index: number, value: any) => {
        const forms = [...get().forms]
        forms[index] = {
          ...forms[index],
          value: value,
        }
        set({forms: forms})
      },
      hiddenForm: (index: number, value: boolean) => {
        const forms = [...get().forms]
        forms[index] = {
          ...forms[index],
          hidden: value,
        }
        set({forms: forms})
      },
      addForms: (form: FormInput) => {
        const forms = [...get().forms]
        forms.push(form)
        set({forms: forms})
      },
      generateForms: (formParam: FormProps) => {
        set({formParam: formParam})
        let forms: FormInput[] = []
        forms.push({
          id: 'banner',
          title: 'Banner',
          placeholder: 'Upload Media (Max file size 2Mb)',
          type: 'file-upload-v2',
          name: 'banner',
          value: get().field?.banner ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          isImage: true,
        })
        forms.push({
          id: 'Images',
          title: 'Images',
          placeholder: 'Upload Media (Max file size 2Mb)',
          type: 'file-upload-multi',
          name: 'images',
          isMulti: true,
          value: get().field?.images ?? [],
          disabled: formParam.action === FormAction.VIEW ? true : false,
        })
        forms.push({
          id: 'title',
          title: 'Title',
          placeholder: 'Title...',
          type: 'text',
          name: 'title',
          value: get().field?.title ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'excerpt',
          title: 'Excerpt',
          placeholder: 'Excerpt...',
          type: 'text',
          name: 'excerpt',
          value: get().field?.excerpt ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'description',
          title: 'Description',
          placeholder: 'Description...',
          type: 'rich-text-ai',
          name: 'description',
          value: get().field?.description ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'program_category_id',
          title: 'Program Category',
          placeholder: 'Program Category...',
          type: 'select-with-text',
          name: 'program_category_id',
          value: {
            label: get().field?.program_category?.name,
            value: get().field?.program_category?.id,
          },
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'donation_type_id',
          title: 'Donation Type',
          placeholder: 'Donation Type...',
          type: 'select-with-text',
          name: 'donation_type_id',
          value: {
            label: get().field?.donation_type?.name,
            value: get().field?.donation_type?.id,
          },
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'program_type',
          title: 'Program Type',
          placeholder: 'Program Type...',
          type: 'select-with-text',
          name: 'program_type',

          value: {
            label: get().field?.program_type,
            value: get().field?.program_type,
          },

          selectData: [
            {label: 'Donasi', value: 'Donasi'},
            {label: 'Kurban', value: 'Kurban'},
            {label: 'Package', value: 'Package'},
          ],
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'is_publish',
          title: 'Publish?',
          placeholder: 'Publish?',
          type: 'checkbox',
          name: 'is_publish',
          value: get().field?.is_publish ?? false,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
          hidden: false,
        })
        forms.push({
          id: 'donation_target',
          title: 'Donation Target',
          placeholder: 'Donation Target...',
          type: 'currency',
          name: 'donation_target',
          value: get().field?.donation_target ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'minimal_donate',
          title: 'Minimal Donasi',
          placeholder: 'Minimal Donasi...',
          type: 'currency',
          name: 'minimal_donate',
          value: get().field?.minimal_donate ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'price',
          title: 'Price (Non Donation Program)',
          placeholder: 'Price (Non Donation Program)...',
          type: 'currency',
          name: 'price',
          value: get().field?.price ?? 0,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'real_price',
          title: 'Real Price (Non Donation Program)',
          placeholder: 'Real Price (Non Donation Program)...',
          type: 'currency',
          name: 'real_price',
          value: get().field?.real_price ?? 0,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'started_at',
          title: 'Start Date',
          placeholder: 'Start Date',
          type: 'date',
          name: 'started_at',
          value: moment(get().field?.started_at).toDate(),
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'finished_at',
          title: 'Finish Date',
          placeholder: 'Finish Date',
          type: 'date',
          name: 'finished_at',
          value: moment(get().field?.finished_at).toDate(),
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: true,
        })
        forms.push({
          id: 'section_one_active',
          title: 'Section One Active?',
          placeholder: 'Section One Active?',
          type: 'checkbox',
          name: 'section_one_active',
          value: get().field?.section_one_active,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'section_two_active',
          title: 'Section Two Active?',
          placeholder: 'Section Two Active?',
          type: 'checkbox',
          name: 'section_two_active',
          value: get().field?.section_two_active,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_favorite',
          title: 'Is Favorite?',
          placeholder: 'Is Favorite?',
          type: 'checkbox',
          name: 'is_favorite',
          value: get().field?.is_favorite,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_must_login',
          title: 'Is Must Login? (Program will shown when user is logged in)',
          placeholder: 'Is Must Login?',
          type: 'checkbox',
          name: 'is_must_login',
          value: get().field?.is_must_login,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_affiliator_only',
          title: 'Is Affiliator Only? (Program will shown for affiliator)',
          placeholder: 'Is Affiliator Only?',
          type: 'checkbox',
          name: 'is_affiliator_only',
          value: get().field?.is_affiliator_only,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'tags',
          title: 'Tags',
          placeholder: 'Tag name...',
          type: 'tags',
          name: 'tags',
          value: get().field?.tags ?? [],
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_footer',
          title: 'Footer On',
          placeholder: 'Footer?',
          type: 'checkbox',
          name: 'is_footer',
          value: get().field?.is_footer ?? false,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'slug',
          title: 'Link (Slug)',
          placeholder: 'Kosongkan jika ingin link disesuaikan dengan title...',
          type: 'text',
          name: 'slug',
          value: get().field?.slug ?? '',
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_hot',
          title: 'Hot Program?',
          placeholder: 'Hot Program??',
          type: 'checkbox',
          name: 'is_hot',
          value: get().field?.is_hot ?? false,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_unlimited',
          title: 'Is Unlimited?',
          placeholder: 'Is Unlimited?',
          type: 'checkbox',
          name: 'is_unlimited',
          value: get().field?.is_unlimited ?? false,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })
        forms.push({
          id: 'is_routine',
          title: 'Program Rutin?',
          placeholder: 'Program Rutin?',
          type: 'checkbox',
          name: 'is_routine',
          value: get().field?.is_routine ?? false,
          disabled: formParam.action === FormAction.VIEW ? true : false,
          required: false,
        })

        set({forms: forms})
      },
      reset: () => {
        set(formInitialState)
        return
      },
      onSubmit: async () => {
        let form = {}
        console.log(get().forms)
        for (const item of get().forms) {
          form = {
            ...form,
            [item.name]: generateFormLogic(item.type, item.value),
          }
        }

        // check required field
        let requiredError = false

        for (const item of get().forms) {
          console.log(item.name, item.required, item.value)
          if (item.required === true && (item.value === '' || item.value === undefined)) {
            toast.error(`${item.title} is required`)
            requiredError = true
          }
          if (item.required === true && (item.value?.value === '' || item.value?.value === 0)) {
            toast.error(`${item.title} is required`)
            requiredError = true
          }
        }

        if (requiredError === true) return

        let res: ProgramSingleResultInterface = {}
        if (get().formParam.action === FormAction.CREATE) res = await SubmitProgram(form)
        if (get().formParam.action === FormAction.UPDATE)
          res = await UpdateProgram(get().formParam?.id, form)

        console.log(res)
        if (res.status) {
          toast.success(res.message)
          set({submitDone: true, formLoading: false, field: undefined})
          return
        } else {
          toast.error(res.message)
          set({submitDone: false, formLoading: false})
          return
        }
      },
      setFormSelectData: (index: number, selectData: SelectData[]) => {
        const forms = [...get().forms]
        forms[index] = {
          ...forms[index],
          selectData: selectData,
        }
        set({forms: forms})
      },
    }),
    {
      name: 'program-form-state',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
