import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdmin = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      {currentUser?.data?.role?.slug === 'admin-pusat' ? (
        <SidebarMenuItem
          to='/apps/admin-pusat-dashboard/dashboard'
          title='Dashboard'
          hasBullet={true}
        />
      ) : (
        <SidebarMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/art/art002.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
      )}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com015.svg'
      >
        <SidebarMenuItemWithSub
          to='/apps/chat/omni-management/omni'
          title='OMNI Channel'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/omni-management/new?status=new'}
            title={'New Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/open?status=open'}
            title={'Open Case'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/omni-management/close?status=close'}
            title={'Close Case'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to='/apps/chat/flow-builder-management/'
          title='Flow Builder'
          hasBullet={true}
        >
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/marketing'}
            title={'Marketing'}
            hasBullet={true}
          />
          <MenuItem
            to={'/apps/chat/flow-builder-management/flow-builder/transactional'}
            title={'Transactional'}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
          to='/apps/chat/broadcast-management/broadcasts'
          title='Broadcast'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/chat/contact-group-management/contact-groups'
          title='Contact Group'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='CRM'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/channel-management/channel'
          title='Channel Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/customer-management/customer'
          title={`${intl.formatMessage({id: 'APPS.CUSTOMER'})} Management`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/prospect-management/prospect'
          title='Prospect Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/task-management/tasks'
          title='Task Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/crm/report-management/report'
          title='E-Report Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/donation'
        title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})}`}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com017.svg'
      >
        <SidebarMenuItem
          to='/apps/donation/donation-management/donation'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})} Management`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Campaign Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/news-management/news'
          title='News Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/banner-management/banner'
          title='Banner Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/program/program-event-banner-management/program-event-banner'
          title='Program Event Banner Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/ffiliator-management'
        title='Affiliate'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com019.svg'
      >
        <SidebarMenuItem
          to='/apps/affiliator-management/affiliator'
          title='Affiliator Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/commission-management/'
          title='Commission Management'
          hasBullet={true}
        />
        {currentUser.data?.company?.id !== 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
          <SidebarMenuItem
            to='/apps/affiliator-bulk-management/affiliator-bulk'
            title='Affiliator (Bulk)'
            hasBullet={true}
          />
        ) : null}

        <SidebarMenuItem
          to='/apps/branch-management/branch'
          title='Branch Management'
          hasBullet={true}
        />
        {currentUser.data?.company?.id === '0d9c2cf4-5534-4a53-84b1-d6eeb9115ba3' ? (
          <>
            <SidebarMenuItem
              to='/apps/korca-management/korca'
              title='Korca Management'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/group-management/work-group'
              title='Group Management'
              hasBullet={true}
            />
            <SidebarMenuItem
              to='/apps/service-unit-management/service-unit'
              title='Unit Management'
              hasBullet={true}
            />
          </>
        ) : null}
        {/* <SidebarMenuItem
          to='/apps/korca-management/korca'
          title='Korca Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/group-management/work-group'
          title='Group Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/service-unit-management/service-unit'
          title='Unit Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/level-affiliator-management/'
          title='Level Affiliator'
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>

      {currentUser.data?.company?.id !== 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
        <SidebarMenuItemWithSub
          to='/apps/program/partner-management'
          title='Mitra'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          <SidebarMenuItem
            to='/apps/program/partner-management/partner'
            title='Mitra Management'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/apps/program/partner-withdraw-management/'
            title='Pencairan Dana'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : null}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section  text-white text-uppercase fs-8 ls-1'>Master Data</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/master/crm'
        title='CRM'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      >
        <SidebarMenuItem
          to='/master/crm/properties-management/properties'
          title='Properties Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/currency-management/currencies'
          title='Currency'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/prospect-channel-management/prospect-channels'
          title={`Channel ${intl.formatMessage({id: 'APPS.TRANSACTION'})}`}
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/report-category-management/report-category'
          title='E-Report Category'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/message-template-management/message-template'
          title='Message Template'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/crm/email-template-management/email-template'
          title='Email Template'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/master/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/master/program/donation-target-management/donation-target'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})} Target`}
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to='/apps/program/channel-management/channel'
          title='Channel'
          hasBullet={true}
        /> */}
        <SidebarMenuItem
          to='/master/program/payment-management/payment'
          title='Payment'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/program-category-management/program-category'
          title='Program Category'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/donation-type-management/donation-type'
          title={`${intl.formatMessage({id: 'APPS.TRANSACTION'})} Type`}
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      {currentUser.data?.company?.id !== 'ba4bc43d-bb0e-4902-acad-56e9c14c4ef4' ? (
        <SidebarMenuItemWithSub
          to='/master/distribution'
          title='Distribusi'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/ecommerce/ecm006.svg'
        >
          <SidebarMenuItem
            to='/master/distribution/distribution-management/distribution-alocation'
            title='Alokasi Distribusi'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : null}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{`User & Role`}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/faq-management/faqs'
        icon='/media/icons/duotune/general/gen051.svg'
        title='FAQ Management'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User Management'
        fontIcon='bi-layers'
      />
      {currentUser?.data?.company?.id === '21db0100-5e59-4a79-90b2-eeb6a7a7097e' ? (
        <>
          <SidebarMenuItem
            to='/apps/role-management/roles'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Role Management'
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/apps/companies-management/companies'
            icon='/media/icons/duotune/general/gen051.svg'
            title='Companies Management'
            fontIcon='bi-layers'
          />
        </>
      ) : (
        <></>
      )}
      <SidebarMenuItem
        to='/apps/platform-donation-settings-management/platform-donation-settings'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Platform'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/company-management/company'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Company'
        fontIcon='bi-layers'
      />
    </>
  )
}

export default SidebarMenuMainAdmin
